<template>
  <v-container>
    <h3 class="text-center">تعديل بيانات الوكيل</h3>
    <h5 class="text-center">{{ agent.email }}</h5>
    <AgentForm :agent="agent" :edit="true">
      <template v-slot:default="slotProps">
        <v-btn
          class="ma-1"
          :disabled="slotProps.disabled"
          @click="updateAgent(slotProps.agent)"
          >هيا. عدل بيانات هذا الوكيل</v-btn
        >
        <v-btn class="ma-1" @click="$router.go(-1)"
          >لا، لا. عد من حيث أتيت</v-btn
        >
      </template>
    </AgentForm>
  </v-container>
</template>

<script>
import AgentForm from "./components/AgentForm";
import { update } from "@/services/agentApi";
import { mapState, mapMutations } from "vuex";

export default {
  components: { AgentForm },
  methods: {
    async updateAgent(agent) {
      const result = await update(agent);
      this.$router.go(-1);
      // this.setAgentToUpdate(null);
    },
    ...mapMutations("agent", {
      setAgentToUpdate: "SET_AGENT_TO_EDIT",
    }),
  },
  computed: {
    ...mapState({
      agent: (state) => state.agent.agent,
    }),
  },
};
</script>

<style>
</style>