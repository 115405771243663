const routes = {
  homeRoute: stringToRouteObject('Home'),
  signinRoute: stringToRouteObject('Signin'),
  forgotPasswordRoute: stringToRouteObject('forgot-password'),
  agentsListRoute: stringToRouteObject('Agents'),
  createAgentRoute: stringToRouteObject('CreateAgent'),
  editAgentRoute: (id) => ({ name: 'EditAgent', params: { id } }),
  tenantsListRoute: stringToRouteObject('Tenants'),
  createTenantRoute: stringToRouteObject('CreateTenant'),
  editTenantRoute: (id) => ({ name: 'EditTenant', params: { id } }),
  notFoundRoute: stringToRouteObject('404'),
}
export default routes

function stringToRouteObject(name) {
  return { name: name }
}