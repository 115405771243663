import axios from './axios'

export async function getAll() {
  return (await axios().get('/tenants')).data
}
export async function create(tenant) {
  return (await axios().post('/tenants', tenant)).data
}
export async function update(tenant) {
  return (await axios().put('/tenants', tenant)).data
}