<template>
  <v-container>
    <router-link
      :to="{ name: 'CreateTenant' }"
      style="text-decoration: none; color: inherit"
    >
      <h3 class="mb-3 d-inline-block">
        هل تريد إنشاء مكتبًا جديدًا؟
        <span class="primary--text">إضغط هنا</span>
      </h3>
    </router-link>

    <v-data-table
      :headers="tableHeaders"
      :items="tenants"
      item-key="id"
      class="elevation-1"
      disable-sort
      :footer-props="{
        'items-per-page-options': [10, 20, 30],
        'items-per-page-text': 'المكاتب المعروضة في كل صفحة',
      }"
    >
      <template v-slot:item.actions="{ item }">
        <v-speed-dial
          v-model="item.fab"
          direction="right"
          :open-on-hover="false"
          transition="slide-x-transition"
        >
          <!-- transition="slide-x-reverse-transition" -->
          <template v-slot:activator>
            <v-btn v-model="item.fab" small text fab>
              <v-icon v-if="item.fab">mdi-close</v-icon>
              <v-icon v-else>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-btn fab dark x-small color="error">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn fab dark x-small color="indigo" @click="goToEdit(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                x-small
                color="green"
                v-bind="attrs"
                v-on="on"
                @click="depositDialogButtonPressed(item)"
              >
                <v-icon>mdi-cash-plus</v-icon>
              </v-btn>
            </template>
            <span>إيداع رصيد</span>
          </v-tooltip>
        </v-speed-dial>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showDepositDialog"
      max-width="360"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <deposit-amount-dialog
        :name="selectedTenant.name"
        :tenantId="selectedTenant.id"
        @cancel="depositDialogButtonPressed"
        @success="depositDialogButtonPressed"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { getAll } from "@/services/tenantApi";
import { mapMutations } from "vuex";
import { ref } from "vue";
import DepositAmountDialog from "@/components/DepositAmountComponent.vue";

export default {
  components: { DepositAmountDialog },
  setup() {
    const showDepositDialog = ref(false);
    const selectedTenant = ref({});
    function depositDialogButtonPressed(tenant) {
      if (showDepositDialog.value) {
        selectedTenant.value = {};
      } else {
        selectedTenant.value = tenant;
      }
      showDepositDialog.value = !showDepositDialog.value;
    }
    return {
      showDepositDialog,
      selectedTenant,
      depositDialogButtonPressed,
    };
  },
  data() {
    return {
      tenants: [],
      tableHeaders: [
        { text: "الإسم", value: "name" },
        { text: "حد الائتمان الأقصى", value: "creditLimit" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    ...mapMutations("tenant", {
      setTenantToUpdate: "SET_TENANT_TO_EDIT",
    }),
    goToEdit(tenant) {
      this.setTenantToUpdate(tenant);
      this.$router.push({ name: "EditTenant", params: { id: tenant.id } });
    },
  },
  async created() {
    const tenants = await getAll();
    tenants.forEach((t) => {
      t.fab = false;
    });
    this.tenants = tenants;
  },
};
</script>

<style>
</style>