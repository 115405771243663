<template>
  <v-container>
    <h3 class="text-center">تعديل بيانات مكتب</h3>
    <h5 class="text-center primary--text">{{ tenant.name }}</h5>
    <TenantForm :tenant="tenant">
      <template v-slot:default="slotProps">
        <v-btn
          class="ma-1"
          :disabled="slotProps.disabled"
          @click="updateTenant(slotProps.tenant)"
          >نعم، هذه هي البيانات الصحيحة</v-btn
        >
        <v-btn class="ma-1" @click="$router.go(-1)"
          >لا، لا. عد من حيث أتيت</v-btn
        >
      </template>
    </TenantForm>
  </v-container>
</template>

<script>
import TenantForm from "./components/TenantForm";
import { update } from "@/services/tenantApi";
import { mapState, mapMutations } from "vuex";

export default {
  components: { TenantForm },
  methods: {
    async updateTenant(tenant) {
      const result = await update(tenant);
      // this.setTenantToUpdate(null);
      this.$router.go(-1);
    },
    ...mapMutations("tenant", {
      setTenantToUpdate: "SET_TENANT_TO_EDIT",
    }),
  },
  computed: {
    ...mapState({
      tenant: (state) => state.tenant.tenant,
    }),
  },
};
</script>

<style>
</style>