import { getAll } from '@/services/tenantApi'
const state = () => ({
  tenant: null,
  tenants: []
})

export default {
  namespaced: true,
  state: state,

  mutations: {
    SET_TENANT_TO_EDIT(state, tenant) {
      state.tenant = tenant
    },
    SET_TENANTS(state, tenants) {
      state.tenants = tenants
    }
  },
  actions: {
    async getAll({ commit }) {
      const tenants = await getAll()
      commit('SET_TENANTS', tenants)
    }
  }
}