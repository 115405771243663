import axios from './axios'

export async function getAll() {
    return (await axios().get('/agents')).data
}
export async function create(agent) {
    return (await axios().post('/agents', agent)).data
}
export async function update(agent) {
    return (await axios().put('/agents', agent)).data
}
export async function remove(agentId) {
    return (await axios().delete(`/agents/${agentId}`)).data
}