<template>
  <v-form v-model="valid">
    <v-text-field
      label="الإسم"
      v-model="localAgent.name"
      required
      :counter="50"
      :rules="nameRules"
    />
    <v-text-field
      type="email"
      label="البريد الإلكتروني"
      v-model="localAgent.email"
      required
      :rules="emailRules"
    />
    <v-text-field
      type="password"
      label="كلمة السر"
      v-model="localAgent.password"
      required
      :rules="passwordRules"
    />
    <v-text-field
      type="tel"
      label="رقم الهاتف"
      v-model="localAgent.phoneNumber"
      required
    />
    <!-- <MazPhoneNumberInput
      v-model="localAgent.phoneNumber"
      default-country-code="EG"
    /> -->
      <!-- :readonly="edit" -->
    <v-select
      v-model="localAgent.tenantId"
      label="المكتب الذي يتبعه الوكيل"
      :items="tenants"
      item-text="name"
      item-value="id"
      :rules="tenantRules"
    ></v-select>
    <v-checkbox
      v-model="localAgent.disabled"
      label="لا تسمح لهذا الوكيل بالحجز حتى إشعارٍ آخر"
    />
    <slot :disabled="!valid" :agent="localAgent"></slot>
  </v-form>
</template>

<script>
import { validateEmail, notBlank } from "@/utils/inputValidation";
import { state, mapActions, mapMutations } from "vuex";

export default {
  props: {
    agent: {
      type: Object,
      default: () => ({
        name: "",
        email: "",
        password: "",
        phoneNumber: "",
        disabled: false,
        tenantId: null,
      }),
    },
    edit: { type: Boolean, default: false },
  },
  data() {
    return {
      valid: false,
      nameRules: [
        (v) => notBlank(v) || "يجب ادخال الإسم",
        (v) => v.length <= 50 || "يجب ألا يزيد الاسم عن 50 حرفاً",
      ],
      emailRules: [
        (v) => notBlank(v) || "العنوان البريدي ضروري",
        (v) =>
          validateEmail(v) || "من فضلك ادخل عنوانًا بريديًا الكترونيًا صحيحًا",
      ],
      passwordRules: [this.nonEmptyPasswordRule, this.lengthPasswordRule],
      tenantRules: [(v) => !!v || "يجب اختيار الوكيل"],
      localAgent: { ...this.agent },
    };
  },
  computed: {
    tenants() {
      return this.$store.state.tenant.tenants;
    },
  },
  methods: {
    ...mapActions("tenant", {
      getAllTenants: "getAll",
    }),
    ...mapMutations("tenant", {
      resetTenants: "SET_TENANTS",
    }),
    nonEmptyPasswordRule(v) {
      if (this.edit) return true;
      return notBlank(v) || "كلمة السر ضرورية";
    },
    lengthPasswordRule(v) {
      if (this.edit) {
        if (!notBlank(v)) return true;
        return v.length >= 6 || "من فضلك ادخل كلمة سر من 6 رموز على الأقل";
      }
      return v.length >= 6 || "من فضلك ادخل كلمة سر من 6 رموز على الأقل";
    },
  },
  async created() {
    await this.getAllTenants();
  },
  destroyed() {
    this.resetTenants(null);
  },
};
</script>

<style>
</style>