<template>
  <v-form v-model="valid">
    <v-text-field
      label="اسم المكتب"
      v-model="localTenant.name"
      required
      :counter="50"
      :rules="nameRules"
    />
    <v-text-field
      hint="الائتمان يتيح الحجز لوكلاء هذا المكتب بدون توفر رصيد. بحد أقصى المبلغ المدخل"
      label="الحد الأقصى للائتمان"
      required
      v-model="localTenant.creditLimit"
      type="number"
      :rules="numberRules"
    />
    <v-checkbox
      v-model="localTenant.disabled"
      label="تعطيل امكانية وكلاء هذا المكتب عن الحجز"
    />
    <slot :disabled="!valid" :tenant="localTenant"></slot>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      nameRules: [
        (v) => !!v || "يجب ادخال الإسم",
        (v) => v.length <= 50 || "يجب ألا يزيد الاسم عن 50 حرفاً",
      ],
      numberRules: [
        (v) => v.toString().length > 0 || "يجب ادخال حد الائتمان",
        (v) => v >= 0 || "يجب ألا يقل حد الائتمان عن صفر",
      ],
      localTenant: { ...this.tenant },
    };
  },
  props: {
    tenant: {
      type: Object,
      default: () => ({ name: "", creditLimit: "", disabled: false }),
    },
  },
};
</script>

<style>
</style>