<template>
  <v-container>
    <router-link
      :to="{ name: 'CreateAgent' }"
      style="text-decoration: none; color: inherit"
    >
      <h3 class="mb-4 d-inline-block">
        هل تريد إضافة وكيلًا جديدًا؟
        <span class="primary--text">إضغط هنا</span>
      </h3>
    </router-link>
    <v-text-field
      v-model="searchText"
      clearable
      label="تبحث عن وكيل؟ من هنا"
      hint="ابحث بالاسم، برقم التليفون. بأي شيء!"
      class="mb-3"
    ></v-text-field>
    <v-data-table
      :headers="tableHeaders"
      :items="agents"
      item-key="id"
      class="elevation-1"
      :search="searchText"
      disable-sort
      :footer-props="{
        'items-per-page-options': $vuetify.breakpoint.xs
          ? [3, 6, 10]
          : [10, 20, 30],
        'items-per-page-text': 'الوكلاء المعروضين في كل صفحة',
      }"
    >
      <template v-slot:item.actions="{ item }">
        <v-speed-dial
          v-model="item.fab"
          direction="right"
          :open-on-hover="false"
          transition="slide-x-transition"
        >
          <!-- transition="slide-x-reverse-transition" -->
          <template v-slot:activator>
            <v-btn v-model="item.fab" small text fab>
              <v-icon v-if="item.fab">mdi-close</v-icon>
              <v-icon v-else>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-btn fab dark x-small color="error" @click="removeAgent(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn fab dark x-small color="indigo" @click="goToEdit(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                x-small
                color="green"
                v-bind="attrs"
                v-on="on"
                @click="depositDialogButtonPressed(item)"
              >
                <v-icon>mdi-cash-plus</v-icon>
              </v-btn>
            </template>
            <span>إيداع رصيد</span>
          </v-tooltip>
        </v-speed-dial>
      </template>
      <template v-slot:item.disabled="{ item }">
        <!-- <v-chip :color="getColor(item.status)" dark class="text-subtitle-1"> {{ item.status }}</v-chip> -->
        <v-icon :color="getColor(item.disabled).color">{{
          getColor(item.disabled).icon
        }}</v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showDepositDialog"
      max-width="360"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <deposit-amount-dialog
        v-if="selectedAgent"
        :name="selectedAgent.name"
        :tenantId="selectedAgent.customClaims.tenant_id"
        @cancel="depositDialogButtonPressed"
        @success="depositDialogButtonPressed"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { getAll, remove } from "@/services/agentApi";
import { mapMutations } from "vuex";
import { ref } from "vue";
import DepositAmountDialog from "@/components/DepositAmountComponent.vue";
export default {
  components: { DepositAmountDialog },
  setup() {
    const searchText = ref("");

    const showDepositDialog = ref(false);
    const selectedAgent = ref();
    function depositDialogButtonPressed(agent) {
      if (showDepositDialog.value) {
        selectedAgent.value = undefined;
      } else {
        selectedAgent.value = agent;
      }
      showDepositDialog.value = !showDepositDialog.value;
    }

    return {
      searchText,
      showDepositDialog,
      selectedAgent,
      depositDialogButtonPressed,
    };
  },
  data() {
    return {
      agents: [],
      tableHeaders: [
        { text: "كود", value: "id" },
        { text: "الإسم", value: "name" },
        { text: "البريد الالكتروني", value: "email" },
        { text: "رقم التليفون", value: "phoneNumber" },
        { text: "الحالة", value: "disabled" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    ...mapMutations("agent", {
      setAgentToUpdate: "SET_AGENT_TO_EDIT",
    }),
    getColor(disabled) {
      if (disabled) return { color: "red lighten-2", icon: "mdi-cancel" };
      return { color: "green lighten-2", icon: "mdi-check-outline" };
    },
    goToEdit(agent) {
      this.setAgentToUpdate(agent);
      this.$router.push({ name: "EditAgent", params: { id: agent.id } });
    },
    async removeAgent(agent) {
      const result = await remove(agent.id);
      if (result.isSuccessful) {
        const index = this.agents.indexOf(agent);
        if (index > -1) this.agents.splice(index, 1);
      }
    },
  },
  async created() {
    const agents = await getAll();
    agents.forEach((t) => {
      t.fab = false;
    });
    this.agents = agents;
  },
};
</script>

<style>
</style>