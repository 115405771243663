import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGaurd } from './routeGaurds'
import { useAuth } from '@/plugins/auth'

import Home from '../views/Home.vue'

import Agent from '../views/agent'
import CreateAgent from '@/views/agent/Create'
import EditAgent from '@/views/agent/Edit'

import Tenant from '../views/tenant'
import CreateTenant from '../views/tenant/Create'
import EditTenant from '../views/tenant/Edit'
import Signin from '@/views/Login.vue'
const ForgotPassword = () => import(/* webpackChunkName: "ForgotPassword" */ "@/views/ForgotPassword.vue");
const FourOFour = () => import(/* webpackChunkName: "FourOFour" */ "@/views/Error.vue");

Vue.use(VueRouter)

const { isAuthenticated, subscribeToAuthenticationEvents } = useAuth()

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
    meta: { requiresSignout: true, layout: 'other' }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: { requiresSignout: true, layout: 'other' }
  },
  {
    path: '/agents',
    name: 'Agents',
    component: Agent,
    meta: { requiresAuth: true }
  },
  {
    path: '/agents/Create',
    name: 'CreateAgent',
    component: CreateAgent,
    meta: { requiresAuth: true }
  },
  {
    path: '/agents/:id/edit',
    name: 'EditAgent',
    component: EditAgent,
    meta: { requiresAuth: true }
  },
  {
    path: '/tenants',
    name: 'Tenants',
    component: Tenant,
    meta: { requiresAuth: true }
  },
  {
    path: '/tenants/Create',
    name: 'CreateTenant',
    component: CreateTenant,
    meta: { requiresAuth: true }
  },
  {
    path: '/tenants/:id/edit',
    name: 'EditTenant',
    component: EditTenant,
    meta: { requiresAuth: true }
  },
  {
    path: '*',
    name: '404',
    component: FourOFour,
    meta: { layout: isAuthenticated.value ? undefined : 'other' }
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach(authGaurd)

import appRoutes from './useAppRoutes'
subscribeToAuthenticationEvents(redirectOnAuthEvents)
function redirectOnAuthEvents(user) {
  if (user) {
    router.replace(appRoutes.homeRoute)
  } else {
    router.replace(appRoutes.signinRoute)
  }
}

export default router
export { useRoute } from './useRouter'