import Vue from 'vue'
import Vuex from 'vuex'
import tenantStore from './tenantStore'
import agentStore from './agentStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tenant: tenantStore,
    agent: agentStore
  }
})
