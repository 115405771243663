<template>
  <v-container>
    <h3 class="mb-3 text-center">إنشاء مكتب جديد</h3>
    <TenantForm>
      <template v-slot:default="slotProps">
        <v-btn class="ma-1"
          :disabled="slotProps.disabled"
          @click="createNewTenant(slotProps.tenant)"
          >نعم، أضف هذا المكتب</v-btn
        >
        <v-btn class="ma-1"
          @click="$router.go(-1)"
          >لا، لا. عد من حيث أتيت</v-btn
        >
      </template>
    </TenantForm>
  </v-container>
</template>

<script>
import TenantForm from "./components/TenantForm";
import { create } from "@/services/tenantApi";

export default {
  components: { TenantForm },
  methods: {
    async createNewTenant(tenant) {
      const result = await create(tenant);
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>