export function notBlank(input) {
    if (input === null || input === undefined)
        return false
    return input.toString().length > 0
}
export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
export function validatePositiveNumber(input) {
    if (Number.isNaN(input)) {
        return false
    }
    return input > 0
}