const state = () => ({
  agent: null,
})

export default {
  namespaced: true,
  state: state,

  mutations: {
    SET_AGENT_TO_EDIT(state, agent) {
      state.agent = agent
    },
  },
}