import { readonly, ref } from "vue"
import { depositAmount } from './depositApi'

const loading = ref(false)

export default function () {
  
  return {
    loading: readonly(loading),
    depositAmountbyTenantId
  }
}
async function depositAmountbyTenantId(tenantId, amount) {
  loading.value = true
  try {
    const result = await depositAmount({ tenantId, amount })
  } finally {
    loading.value = false
  }
}