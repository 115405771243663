<template>
  <v-card>
    <v-card-title class="text-h5"> إيداع رصيد </v-card-title>
    <v-card-text>
      أدخل المبلغ الذي تريد إيداعه في رصيد
      <span class="text-subtitle-2 secondary--text">{{ name }}</span>
    </v-card-text>
    <v-form ref="form" v-model="validForm">
      <v-text-field
        type="number"
        outlined
        class="mx-10"
        v-model="amount"
        :rules="amoutValidations"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="warning"
          @click="submitDeposit"
          :loading="loading"
          :disabled="!validForm"
        >
          نعم، أتم الإيداع
        </v-btn>
        <v-btn color="green lighten-2" text @click="cancelDialog">
          ليس بعد
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { ref } from "vue";
import useDeposit from "@/deposit/useDeposit";
import { validatePositiveNumber } from "@/utils/inputValidation";

export default {
  props: ["name", "tenantId"],
  setup(props, { emit }) {
    function clearForm() {
      form.value.reset();
      form.value.resetValidation();
    }
    const form = ref();
    const validForm = ref(false);
    const amount = ref();
    const amoutValidations = [
      (v) => validatePositiveNumber(v) || "ادخل رقمًا أكبر من صفر",
    ];
    const { loading, depositAmountbyTenantId } = useDeposit();
    async function submitDeposit() {
      const value = Number.parseFloat(amount.value);
      await depositAmountbyTenantId(props.tenantId, value);
      clearForm();
      emit("success");
    }
    function cancelDialog() {
      clearForm();
      emit("cancel");
    }
    return {
      form,
      amount,
      cancelDialog,
      submitDeposit,
      loading,
      amoutValidations,
      validForm,
    };
  },
};
</script>

<style>
</style>